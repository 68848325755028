<template>
    <div class="column is-one-quarter has-borders-column">
        <span class="heading-inline">Metody wpłaty</span>
        <br />
        <FundingMethodsList :fundingMethods="broker.fundingMethods"></FundingMethodsList>
    </div>
</template>

<script>
import FundingMethodsList from '../FundingMethodsList';

export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
    },
    components: {
        FundingMethodsList,
    },
};
</script>
