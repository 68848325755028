<template>
  <div class="box">
    <div class="notification is-light is-info has-text-centered" v-if="addToCompare && broker.showPopularity"
      @mouseover="mouseOver.popularity = true" @mouseleave="mouseOver.popularity = false">
      <lord-icon animation="hover" params="100" target="div" src="/lordicon/system-regular-96-groups.json"></lord-icon>

      <strong> {{ broker.popularity }}</strong> użytkowników JakiBroker.pl wybrało <strong>{{ broker.name
        }}</strong>&nbsp;
      <span @click.stop.prevent="showInfoModalWith('popularity')">
        <font-awesome-icon v-show="mouseOver.popularity || isMobile()"
          class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
      </span>
    </div>
    <a :href="createAccountLink('/list/box')" style="color: #363636">
      <article class="media">
        <img :src="broker.imageSrc" style="max-width: 128px; padding-right: 1rem;"
          :alt="'Logo brokera ' + broker.name" />
        <div class="media-content not-overflow">
          <div class="container padding-bottom-1rem">
            <lord-icon v-if="index <= 2" animation="hover" params="100" :palette="ribbonPalette[index]" target="div"
              src="/lordicon/434-prize-padge-ribbon-outline.json"></lord-icon>
            <span v-if="broker.isRecommended" class="tag is-info is-light">REKOMENDOWANY</span>
          </div>

          <div v-if="noFields">
            <p>
              Dokonuj tylko głęboko przemyślanych decyzji inwestycyjnych.
              Wyłączenie wszystkich pól nie jest dobrą drogą.
            </p>
            <a href="https://knowyourmeme.com/memes/stonks" rel="noreferrer noopener nofollow" target="_blank">
              <img src="/img/stonks.jpg" />
            </a>
          </div>
        </div>
        <button class="button" :class="{ 'is-success': addedToComparison }" @click.stop.prevent="clickAddToCompare"
          title="Dodaj do porównania." v-if="addToCompare" :disabled="compareBoxLimitReached && !addedToComparison">
          <span v-if="addedToComparison">usuń</span>
          <span v-else>porównaj</span>
          <span v-if="addedToComparison" class="icon">
            <lord-icon animation="hover" params="200" palette="#ffffff;#ffffff"
              src="/lordicon/67-magnifier-minus-outline-edited.json"></lord-icon>
          </span>
          <span v-else class="icon">
            <lord-icon animation="hover" params="200" palette="#121331;#03a9f4"
              src="/lordicon/65-magnifier-plus-outline.json"></lord-icon>
          </span>
        </button>
      </article>
      <article class="margin-top-1rem margin-bottom-1rem">
        <div class="container padding-bottom-1rem">

        </div>
        <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
          <div class="columns is-multiline" v-if="computedFields.description">
            <div class="column is-full">
              <strong>{{ broker.name }}</strong> - {{ broker.description }}
            </div>
            <div class="column is-full" v-if="broker.review">
              <small>
                <router-link :to="{
                  name: 'article',
                  params: { slug: broker.review.slug },
                }">{{ broker.review.text }}</router-link></small>
            </div>
          </div>
        </transition>

      </article>
      <article class="media">
        <div class="media-content not-overflow">
          <div class="columns is-multiline">

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <RateField v-if="computedFields.rate" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <MinInstallmentField v-if="computedFields.minInstallment" :broker="broker" :sorting="sorting" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <CurrenciesField v-if="computedFields.currencies" :broker="broker"
                :selectedAccountCurrency="selectedAccountCurrency" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <CountryOfOriginField v-if="computedFields.countryOfOrigin" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <CountryOfRegulationField v-if="computedFields.countryOfRegulation" :broker="broker"
                :selectedCountryOfRegulation="selectedCountryOfRegulation" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <InvestmentProtectionField v-if="computedFields.investmentProtection" :broker="broker" />
            </transition>


            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <InterestRateField v-if="computedFields.interestRate" :broker="broker" :sorting="sorting" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <PlatformsField v-if="computedFields.platforms" :broker="broker" :selectedPlatform="selectedPlatform" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <InstrumentsField v-if="computedFields.instruments" :broker="broker"
                :selectedInstrument="selectedInstrument" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <FundingMethodsField v-if="computedFields.fundingMethods" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <SpreadField v-if="computedFields.spread" :broker="broker" :sorting="sorting" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <KalkulatorgieldowyField v-if="computedFields.kalkulatorgieldowy" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <CommissionField v-if="computedFields.commission" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <LeverageField v-if="computedFields.leverage" :broker="broker" :sorting="sorting" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <EcnField v-if="computedFields.ecn" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <FractionalSharesField v-if="computedFields.fractionalShares" :broker="broker" />
            </transition>

            <transition enter-active-class="animated flipInX fast" leave-active-class="animated flipOutX fast">
              <PopularityField v-if="computedFields.popularity" :broker="broker" :sorting="sorting" />
            </transition>

          </div>
        </div>
      </article>
      <article class="media">
        <div class="media-content not-overflow">
          <div class="columns is-multiline">
            <div class="column">
              <a :href="createAccountLink('/list/button')">
                <button class="button is-info is-medium is-fullwidth">
                  <span>ZAŁÓŻ KONTO</span>
                  <span class="icon">
                    <lord-icon animation="hover" target="button" params="200" palette="#ffffff;#00000"
                      src="/lordicon/20-love-heart-outline.json"></lord-icon>
                  </span>
                </button>
              </a>
            </div>
            <div class="column is-one-quarter">
              <router-link :to="{ name: 'brokerdetail', params: { slug: broker.slug } }">
                <button class="button is-medium is-fullwidth">
                  <span>SZCZEGÓŁY</span>
                  <span class="icon">
                    <lord-icon animation="hover" target="button" params="200" palette="#00000;#3297dc"
                      src="/lordicon/19-magnifier-zoom-search-outline.json"></lord-icon>
                  </span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="columns">
            <div class="column is-fullwidth">
              <small>{{ broker.disclaimer }}</small>
            </div>
          </div>
        </div>
      </article>
    </a>
    <hr v-if="addToCompare">

    <article class="margin-top-1rem margin-bottom-1rem has-text-centered"
      v-if="isFeatureEnabled('XTB_MASTERCLASS') && addToCompare && broker.slug == 'xtb'">
      <a href="/open/xtb-masterclass/">
        <img src="/img/banners/xtb-masterclass.jpg" />
      </a>
    </article>

    <article class="margin-top-1rem margin-bottom-1rem" v-if="addToCompare">
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="buttons is-centered">
            <router-link tag="button"
              :to="{ name: 'compare', params: { slugs: [broker.slug, brokerToCompare.slug].join('-vs-') } }"
              v-for="brokerToCompare in brokersToCompareWith" :key="brokerToCompare.slug"
              class="button is-link is-inverted">
              <span class="icon">
                <figure class="container image">
                  <img :src="broker.imageSrc" :alt="broker.name" />
                </figure>
              </span>
              <span>{{ broker.name }} vs {{ brokerToCompare.name }}</span>
              <span class="icon">
                <figure class="container image">
                  <img :src="brokerToCompare.imageSrc" :alt="brokerToCompare.name" />
                </figure>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import RateField from './fields/RateField';
import MinInstallmentField from './fields/MinInstallmentField';
import CurrenciesField from './fields/CurrenciesField';
import CountryOfOriginField from './fields/CountryOfOriginField';
import CountryOfRegulationField from './fields/CountryOfRegulationField';
import InvestmentProtectionField from './fields/InvestmentProtectionField';
import InterestRateField from './fields/InterestRateField';
import PlatformsField from './fields/PlatformsField';
import InstrumentsField from './fields/InstrumentsField';
import FundingMethodsField from './fields/FundingMethodsField';
import SpreadField from './fields/SpreadField';
import KalkulatorgieldowyField from './fields/KalkulatorgieldowyField';
import CommissionField from './fields/CommissionField';
import LeverageField from './fields/LeverageField';
import EcnField from './fields/EcnField';
import FractionalSharesField from './fields/FractionalSharesField';
import PopularityField from './fields/PopularityField.vue';

export default {
  name: "BrokerListElement",
  components: {
    RateField,
    MinInstallmentField,
    CurrenciesField,
    CountryOfOriginField,
    CountryOfRegulationField,
    InvestmentProtectionField,
    InterestRateField,
    PlatformsField,
    InstrumentsField,
    FundingMethodsField,
    SpreadField,
    KalkulatorgieldowyField,
    CommissionField,
    LeverageField,
    EcnField,
    FractionalSharesField,
    PopularityField,
  },
  props: [
    "broker",
    "addToCompare",
    "removeFromCompare",
    "fields",
    "index",
    "landing",
    "linkSuffix",
  ],
  methods: {
    clickAddToCompare() {
      if (this.addedToComparison) {
        this.removeFromCompare(this.broker.slug);
      } else {
        this.addToCompare(this.broker.slug);
      }
    },
    createAccountLink(place) {
      if (this.linkSuffix) {
        return (
          this.broker.create_account_link + this.linkSuffix + this.addRef()
        );
      }
      return (
        this.broker.create_account_link +
        (this.landing ? `/landing${place}` : place) +
        this.addRef()
      );
    },
  },
  computed: {
    compare() {
      return this.$store.getters.getCompareBox;
    },
    compareBoxLimitReached() {
      if (this.isMobile()) {
        return this.compare.length >= 2;
      }
      return this.compare.length >= 4;
    },
    addedToComparison() {
      return this.compare.includes(this.broker.slug);
    },
    computedFields() {
      if (Array.isArray(this.fields)) {
        return this.fields.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
      return this.fields;
    },
    noFields() {
      return Object.values(this.fields).every((elem) => elem === false);
    },
    selectedPlatform() {
      return this.$store.state.filter.platform;
    },
    selectedInstrument() {
      return this.$store.state.filter.instrument;
    },
    selectedAccountCurrency() {
      return this.$store.state.filter.accountCurrency;
    },
    selectedCountryOfRegulation() {
      return this.$store.state.filter.countryOfRegulation;
    },
    sorting() {
      return this.$store.getters.sorting;
    },
    brokersToCompareWith() {
      const brokers = this.$store.state.brokers.filter(
        (broker) => broker.slug !== this.broker.slug
      ).slice(0, 3);
      return brokers;
    },
  },
  data() {
    return {
      ribbonPalette: {
        0: "#DAA520;#DAA520",
        1: "#C0C0C0;#C0C0C0",
        2: "#CD7F32;#CD7F32",
      },
      mouseOver: {
        leverage: false,
        investmentProtection: false,
        platform: false,
        spread: false,
        ecn: false,
        kalkulatorgieldowy: false,
        interestRate: false,
        popularity: false,
      },
    };
  },
};
</script>

<style>
.is-borderless {
  border: none;
}

lord-icon {
  width: 50px;
  height: 50px;
}

.has-background-gold {
  background-color: gold;
}

.help-icon {
  width: 25px;
  height: 25px;
}
</style>
