<template>
  <div class="columns">
    <div class="column is-3"></div>
    <div class="column content padding-1rem">
      <div class="has-text-centered">
        <picture>
          <source type="image/webp" :srcset="definition.coverImageSrcWebp" />
          <img :src="definition.coverImageSrcJpg" :alt="definition.coverImageAlt" />
        </picture>
      </div>
      <div class="columns is-gapless" v-if="definition.coverImageSrcJpg && definition.coverImageAuthor">
        <div class="column"></div>
        <div class="column is-3">
          <small>Image by
            <a :href="definition.coverImageAuthorLink" rel="noreferrer
          noopener nofollow" target="_blank">{{ definition.coverImageAuthor }}</a>
          </small>
        </div>
      </div>

      <component :is="definitionsLoader" :key="$route.params.slug">
        <template #smallCompare="smallCompareProps">
          <side-to-side-compare-columns :brokers="smallCompareProps.brokers" :isAllFields="false"
            :fields="smallCompareProps.fields">
          </side-to-side-compare-columns>
        </template>
        <template #openAccount="openAccountProps">
          <BrokerListElement :broker="loadBroker(openAccountProps.slug)" :index="0"
            :fields="openAccountProps.fields || fields" :linkSuffix="openAccountProps.linkSuffix" />
        </template>
      </component>
      <div class="margin-top-1rem">
        <RankingWidget v-if="ranking" :ranking="ranking"></RankingWidget>
      </div>
      <div v-if="definition.relatedArticles.length > 0">
        <h2 class="has-fancy-underline padding-top-2rem has-text-centered">
          Powiązane artykuły
        </h2>
        <ul class="features-list">
          <li v-for="article in definition.relatedArticles" :key="article.slug">
            <router-link :to="{ name: 'article', params: { slug: article.slug } }">{{ article.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="column is-3"></div>
  </div>
</template>

<script>
import definitions from "../definitions.json";
import RankingWidget from "./RankingWidget";
import BrokerListElement from "./BrokerListElement";
import SideToSideCompareColumns from "./SideToSideCompareColumns";

export default {
  data() {
    return {
      definition: null,
      ranking: null,
      fields: {
        description: false,
        rate: true,
        minInstallment: false,
        currencies: false,
        countryOfOrigin: false,
        investmentProtection: false,
        platforms: false,
        countryOfRegulation: false,
        instruments: true,
        spread: false,
        commission: false,
        fundingMethods: true,
        leverage: true,
        ecn: false,
      },
    };
  },
  methods: {
    setState() {
      this.definition = definitions.find(
        (elem) => elem.slug == this.$route.params.slug
      );
      this.$store.commit("setCurrentPageTitle", this.definition.title);
      if (this.definition.ranking !== null) {
        this.ranking = this.$store.state.rankings.find(
          (elem) => elem.slug == this.definition.ranking
        );
      }
    },
    loadBroker(slug) {
      return this.$store.getters.databaseBrokers.find(
        (broker) => broker.slug == slug
      );
    },
  },
  computed: {
    definitionsLoader() {
      // it's here just to invoke reactivity
      this.$route.params.slug;
      return () => import(`@/definitions/${this.$route.params.slug}.md`);
    },
    metaTitle() {
      return this.definition.teaser;
    },
    metaDescription() {
      return this.definition.description;
    },
    metaImageSrc() {
      return this.definition.coverImageSrcJpg;
    },
    metaUrlContent() {
      return `https://jakibroker.pl/slownikpojec/${this.definition.slug}/`;
    },
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },

        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        { property: "og:image", content: this.metaImageSrc },
        { property: "og:image:url", content: this.metaImageSrc },
        { property: "og:image:width", content: 1200 },
        { property: "og:image:height", content: 628 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "JakiBroker.pl - %s",
    };
  },
  mounted() {
    this.setState();
  },
  watch: {
    $route() {
      this.setState();
    },
  },
  components: {
    RankingWidget,
    SideToSideCompareColumns,
    BrokerListElement,
  },
};
</script>
