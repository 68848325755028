import Vue from "vue";
import { library } from "../node_modules/@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "../node_modules/@fortawesome/vue-fontawesome";

import {
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowDown,
  faArrowUp,
  faBuilding,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCoins,
  faCreditCard,
  faDollarSign,
  faExchangeAlt,
  faFlag,
  faGavel,
  faGlobeEurope,
  faHandHoldingUsd,
  faMoneyBillWave,
  faQuestion,
  faRocket,
  faSearchPlus,
  faSlidersH,
  faTimesCircle,
  faUser,
  faHourglassHalf,
} from "../node_modules/@fortawesome/free-solid-svg-icons";

import {
  faApplePay,
  faBtc,
  faPaypal,
  faCcMastercard,
  faCcVisa,
} from "../node_modules/@fortawesome/free-brands-svg-icons";

library.add(
  faApplePay,
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowDown,
  faArrowUp,
  faBtc,
  faBuilding,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCoins,
  faCreditCard,
  faDollarSign,
  faExchangeAlt,
  faFlag,
  faGavel,
  faGlobeEurope,
  faHandHoldingUsd,
  faMoneyBillWave,
  faPaypal,
  faQuestion,
  faRocket,
  faSearchPlus,
  faSlidersH,
  faTimesCircle,
  faUser,
  faCcMastercard,
  faCcVisa,
  faHourglassHalf
);

Vue.component("font-awesome-icon", FontAwesomeIcon); // registered globally
