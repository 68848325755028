<template>
  <router-link :to="{ name: 'brokerranking', params: { slug: ranking.slug } }">
    <div class="box article-title has-text-centered">
      <h3>{{ ranking.title }}</h3>
      <router-link :to="{ name: 'brokerranking', params: { slug: ranking.slug } }">
        <picture>
          <source type="image/webp" :srcset="ranking.coverImageSrcWebp" />
          <img :src="ranking.coverImageSrcJpg" :alt="ranking.coverImageAlt" />
        </picture>
      </router-link>
      <router-link :to="{ name: 'brokerranking', params: { slug: ranking.slug } }">
        <a class="button is-info is-fullwidth wrap-button-text">
          <span>{{ ranking.teaserLink }} </span>
          <span class="icon">
            <font-awesome-icon icon="arrow-circle-right"></font-awesome-icon>
          </span>
        </a>
      </router-link>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["ranking"],
};
</script>
