<template>
  <footer class="footer has-background-link has-text-light margin-top-2rem">
    <h3 class="subtitle has-text-centered has-text-white">
      Najczęściej zadawane pytania
    </h3>
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column">
        <article class="message is-link">
          <FooterQuestion v-for="question in questions" :key="question.question" :question="question"></FooterQuestion>
        </article>
      </div>
      <div class="column is-3"></div>
    </div>
    <div class="has-text-centered padding-bottom-2rem" v-if="isFeatureEnabled('XTB_MASTERCLASS')">
      <a href="/open/xtb-masterclass/" target="_blank" rel="noopener noreferrer">
        <img src="/img/banners/footer-xtb-masterclass.jpg" alt="XTB banner masterclass">
      </a>

    </div>
    <div class="content has-text-centered">
      <p>
        <strong class="has-text-white">JakiBroker.pl</strong> by Wonsy sp. z o.
        o. Wszelkie prawa zastrzeżone.
      </p>

      <p>
        <strong class="has-text-white">Inwestowanie jest ryzykowne i możesz stracić część lub całość zainwestowanego
          kapitału. Podane informacje służą wyłącznie celom informacyjnym i edukacyjnym i nie stanowią żadnego rodzaju
          porady finansowej ani rekomendacji inwestycyjnej.</strong>
      </p>
      <p>
        <strong class="has-text-white">
          Uwaga! Nie udzielamy porad inwestycyjnych. Żadna forma kontaktu z nami
          nie może służyć do udzielania porad inwestycyjnych.
        </strong>
      </p>
    </div>
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <h3 class="has-text-weight-bold">JakiBroker.pl</h3>
            <ul>
              <li>
                <router-link class="has-text-white" :to="{
                  name: 'sitemap',
                }">Mapa strony</router-link>
              </li>
              <li>
                <a class="has-text-white" href="mailto:jakibroker@wonsystudio.com?subject=Zgłoś błąd na stronie">Zgłoś
                  błąd na stronie</a>
              </li>
              <li>
                <a class="has-text-white" href="mailto:jakibroker@wonsystudio.com?subject=Reklama">Reklama</a>
              </li>
              <li>
                <a class="has-text-white" type="application/json" target="_blank" ref="noreferrer noopener"
                  href="/static/Regulamin_jakibrokerpl.pdf">Regulamin</a>
              </li>
              <li>
                <a class="has-text-white" type="application/json" target="_blank" ref="noreferrer noopener"
                  href="/static/Polityka_Prywatności_jakibrokerpl.pdf">Polityka prywatności</a>
              </li>
            </ul>
            <h3 class="has-text-weight-bold">Wiesz więcej</h3>
            <ul class="content">
              <li>
                <router-link class="has-text-white" :to="{
                  name: 'definition',
                  params: { slug: 'platforma-transakcyjna' },
                }">Co to jest platforma transakcyjna?</router-link>
              </li>
              <li>
                <router-link class="has-text-white" :to="{ name: 'definition', params: { slug: 'dzwignia' } }">Co to
                  dźwignia?</router-link>
              </li>
            </ul>
          </div>
          <div class="column">
            <h3 class="has-text-weight-bold">Współpraca</h3>
            <ul class="content">
              <li>
                <a class="has-text-white" href="mailto:jakibroker@wonsystudio.com?subject=Współpraca">Napisz do nas</a>
              </li>
              <li>
                <a href="https://kalkulatorgieldowy.pl" class="has-text-white" rel="noopener"
                  target="_blank">kalkulatorgieldowy.pl - Rozlicz PIT z giełdy zagranicznej</a>
              </li>
              <li>
                <a href="https://zbierajmonety.pl" class="has-text-white" rel="noopener" target="_blank">Inwestowanie w
                  monety bulionowe</a>
              </li>
            </ul>
          </div>
          <div class="column">
            <h3 class="has-text-weight-bold">Zobacz także</h3>
            <ul class="content">
              <li>
                <a href="https://jakibroker.pl" class="has-text-white">jakiego brokera wybrać</a>
              </li>

              <li>
                <router-link class="has-text-white" :to="{
                  name: 'brokerranking',
                  params: { slug: 'brokerzy-z-najnizszym-spreadem' },
                }">Brokerzy z najniższym spreadem</router-link>
              </li>
              <li>
                <router-link class="has-text-white" :to="{
                  name: 'brokerranking',
                  params: { slug: 'brokerzy-ecn' },
                }">Najlepsi Brokerzy ECN</router-link>
              </li>
              <li>
                <router-link class="has-text-white" :to="{
                  name: 'definition',
                  params: { slug: 'forex' },
                }">
                  forex
                </router-link>
              </li>
              <li>
                <router-link class="has-text-white" :to="{
                  name: 'definition',
                  params: { slug: 'kryzys-finansowy' },
                }">
                  kryzys finansowy
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="column is-3"></div>
    </div>
  </footer>
</template>

<script>
import FooterQuestion from "./FooterQuestion.vue";
import footerQuestions from "@/footerQuestions.json";
export default {
  name: "Footer",
  computed: {
    questions() {
      return footerQuestions;
    },
  },
  components: {
    FooterQuestion,
  },
};
</script>
