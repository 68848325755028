<template>
  <div
    class="has-text-centered margin-left-right-2rem"
    @mouseover="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <h2 class="subtitle is-4">
      {{ question.text }}
      <span @click="showInfoModalWith(question.helpDefinition)">
        <font-awesome-icon
          v-show="question.helpDefinition && (mouseOver || isMobile())"
          class="clickable-icon has-text-link animated tada infinite"
          icon="question"
        ></font-awesome-icon>
      </span>
    </h2>

    <div class="field" v-for="option in question.options" :key="option.id">
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            v-model="selectedOption"
            :id="option.id"
            :value="option.text"
          />
          {{ option.text }}
        </label>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <button @click="prevHandler(selectedOption)" class="button">
          <span class="icon">
            <font-awesome-icon icon="arrow-circle-left"></font-awesome-icon>
          </span>
          <span>Poprzednie</span>
        </button>
      </div>
      <div class="column">
        <button @click="nextHandler(selectedOption)" class="button is-info">
          <span>Następne</span>
          <span class="icon">
            <font-awesome-icon icon="arrow-circle-right"></font-awesome-icon>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  data() {
    return {
      mouseOver: false,
      selectedOption: null,
    };
  },
  props: ["question", "nextHandler", "prevHandler"],
};
</script>

<style scoped>
.margin-left-right-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
