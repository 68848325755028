var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-one-quarter has-borders-column"},[_c('span',{staticClass:"heading-inline"},[_vm._v("Instrumenty")]),_c('br'),_c('div',{staticClass:"tags"},[(_vm.broker.onlyCFD)?_vm._l((_vm.broker.instruments),function(instrument){return _c('span',{key:instrument,staticClass:"tag",class:{
            'is-link is-light':
                _vm.selectedInstrument &&
                instrument.toLowerCase() ==
                _vm.selectedInstrument.toLowerCase(),
        }},[_vm._v(_vm._s(instrument)+" (CFD)")])}):_vm._l((_vm.broker.instruments),function(instrument){return _c('span',{key:instrument,staticClass:"tag",class:{
            'is-link is-light':
                _vm.selectedInstrument &&
                instrument.toLowerCase() ==
                _vm.selectedInstrument.toLowerCase(),
        }},[_vm._v(_vm._s(instrument))])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }