<template>

    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false"
        :class="{
            'has-background-info-light': sorting == sortingOptions.popularity,
        }">
        <span class="heading-inline">Popularność</span>
        <span @click.stop.prevent="showInfoModalWith('popularity')">
            <font-awesome-icon v-show="mouseOver || isMobile()" class="clickable-icon has-text-link animated tada"
                icon="question"></font-awesome-icon>
        </span>
        <br />

        <span v-if="!!broker.popularity">{{ broker.popularity.toLocaleString("pl-PL") }}</span>
        <small v-else>Zbieramy dane.</small>

    </div>
</template>

<script>
import { sortingOptions } from '../BrokerList.constants';

export default {
    data() {
        return {
            mouseOver: false,
            sortingOptions,
        };
    },
    props: {
        broker: {
            type: Object,
            required: true,
        },
        sorting: {
            type: String,
            required: false,
        },
    },
}
</script>
