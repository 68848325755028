import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/sass/styles.scss";
import "./fa.config.js";
import store from "./store";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import VueJsonLD from 'vue-jsonld'

Vue.use(VueMeta);
Vue.use(VueJsonLD)
Vue.use(VueGtag, {
  config: { id: "G-YRJ9YLZMWM" },
});

defineLordIconElement(loadAnimation);

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    goToBrokerList() {
      router.push({ name: "brokerlist", hash: "#brokerList" });
    },
    showInfoModalWith(what) {
      this.$gtag.event("showInfoModalWith", { value: what });
      store.commit("showInfoModalWith", what);
    },
    isMobile() {
      return screen.width < 768;
    },
    isIn(item, collection) {
      return (
        collection.findIndex((i) => i.toLowerCase() == item.toLowerCase()) != -1
      );
    },
    addRef() {
      let referrer = document.referrer;
      if (referrer) {
        return btoa("09wGJGnNWW" + new URL(referrer).host) ==
          "MDl3R0pHbk5XV3pha3VwYWtjamkucGw="
          ? atob("L3JlZg==")
          : "";
      }
      return "";
    },
    loadBroker(slug) {
      return this.$store.getters.databaseBrokers.find(
        (broker) => broker.slug == slug
      );
    },
    isLocalhost() {
      return window.location.origin.includes("localhost");
    },
    isTesting() {
      return window.location.origin.includes("jakibroker-testing");
    },
    isProd() {
      return window.location.origin.includes("jakibroker.pl");
    },
    isFeatureEnabled(feature) {
      if (this.isLocalhost()) {
        return this.feature[feature].local;
      }
      if (this.isTesting()) {
        return this.feature[feature].testing;
      }
      if (this.isProd()) {
        return this.feature[feature].prod;
      }
      return false;
    },
    getFullUrlBrokerImage(broker) {
      if (this.isLocalhost()) {
        return `http://localhost:8000${broker.imageSrc}`;
      }
      if (this.isTesting()) {
        return `http://jakibroker-testing.s3-website.eu-central-1.amazonaws.com${broker.imageSrc}`;
      }
      if (this.isProd()) {
        return `https://jakibroker.pl${broker.imageSrc}`;
      }
      return broker.imageSrc;
    },
  },
  data() {
    return {
      flags: {
        pln: "flag-icon-pl",
        eur: "flag-icon-eu",
        polska: "flag-icon-pl",
        usd: "flag-icon-us",
        "stany zjednoczone": "flag-icon-us",
        gbp: "flag-icon-gb",
        "wielka brytania": "flag-icon-gb",
        niemcy: "flag-icon-de",
        włochy: "flag-icon-it",
        holandia: "flag-icon-nl",
        kanada: "flag-icon-ca",
        cad: "flag-icon-ca",
        francja: "flag-icon-fr",
        aud: "flag-icon-au",
        australia: "flag-icon-au",
        chf: "flag-icon-at",
        huf: "flag-icon-hu",
        belize: "flag-icon-bz",
        cypr: "flag-icon-cy",
        sgd: "flag-icon-sg",
        singapur: "flag-icon-sg",
        "nowa zelandia": "flag-icon-nz",
        japonia: "flag-icon-jp",
        "hong kong": "flag-icon-hk",
        indie: "flag-icon-in",
        izrael: "flag-icon-il",
        "republika południowej afryki": "flag-icon-za",
        seszele: "flag-icon-sc",
        malta: "flag-icon-mt",
        rub: "flag-icon-ru",
        vanuatu: "flag-icon-vu",
        finlandia: "flag-icon-fi",
        hiszpania: "flag-icon-es",
        szwecja: "flag-icon-se",
        węgry: "flag-icon-hu",
        czk: "flag-icon-cz",
        dkk: "flag-icon-dk",
        nok: "flag-icon-no",
        sek: "flag-icon-se",
        hkd: "flag-icon-hk",
        jpy: "flag-icon-jp",
        nzd: "flag-icon-nz",
        try: "flag-icon-tr",
        dania: "flag-icon-dk",
        szwajcaria: "flag-icon-ch",
        malezja: "flag-icon-my",
        rpa: "flag-icon-za",
        "zjednoczone emiraty arabskie": "flag-icon-ae",
        "saint vincent i grenadyny": "flag-icon-vc",
        irlandia: "flag-icon-ie",
        "brytyjskie wyspy dziewicze": "flag-icon-vg",
      },
      feature: {
        AUTHOR_BOX: {
          local: false,
          testing: false,
          prod: false,
        },
        XTB_MASTERCLASS: {
          local: false,
          testing: false,
          prod: false,
        },
      }
    };
  },
});

router.beforeEach((to, from, next) => {
  store.commit("closeInfoModal");
  next();
});

// register lottie and define custom element
const root = new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");

document.addEventListener("DOMContentLoaded", function () {
  root.$mount("#app");
  document.dispatchEvent(new Event("vue-post-render"));
});
