<template>

    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false"
        :class="{
            'has-background-info-light': sorting == sortingOptions.lowestSpread,
        }">
        <span class="heading-inline">Spread</span>
        <span @click.stop.prevent="showInfoModalWith('spread')">
            <font-awesome-icon v-show="mouseOver || isMobile()" class="clickable-icon has-text-link animated tada"
                icon="question"></font-awesome-icon>
        </span>
        <br />
        <ul class="features-list">
            <li v-for="spreadInfo in broker.spread" :key="spreadInfo">
                <small>{{ spreadInfo }}</small>
            </li>
        </ul>
    </div>
</template>

<script>
import { sortingOptions } from '../BrokerList.constants';

export default {
    data() {
        return {
            mouseOver: false,
            sortingOptions,
        };
    },
    props: {
        broker: {
            type: Object,
            required: true,
        },
        sorting: {
            type: String,
            required: false,
        },
    },
};
</script>