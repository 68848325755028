<template>
  <section class="section">
    <div class="columns" v-if="$route.name == 'articlelist'">
      <div class="column is-3"></div>
      <div class="column">
        <h1
          class="title has-text-centered has-fancy-underline padding-bottom-1rem"
        >
          Baza wiedzy
        </h1>
      </div>
      <div class="column is-3"></div>
    </div>
    <div v-if="$route.name == 'articlelist'" class="columns">
      <div class="column is-2"></div>
      <div class="column content">
        <div class="columns is-multiline">
          <div
            class="column is-half"
            v-for="article in articles"
            :key="article.slug"
          >
            <ArticleWidget :article="article"></ArticleWidget>
          </div>
        </div>
      </div>
      <div class="column is-2"></div>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>
import articles from "@/articles.json";
import ArticleWidget from "./ArticleWidget";

export default {
  data() {
    return {
      articles: articles.filter((elem) => elem.isPublic),
    };
  },
  components: {
    ArticleWidget,
  },
};
</script>
