<template>
  <div>
    <div class="columns is-vcentered is-gapless">
      <div class="column is-3"></div>
      <div class="column">
        <h1 class="title has-fancy-underline has-text-centered">
          {{ metaTitle }}
        </h1>
        <div class="content has-text-justified padding-1rem">
          <component :is="comparisonLoader" :key="$route.params.slug">
            <template #smallCompare="smallCompareProps">
              <side-to-side-compare-columns :brokers="smallCompareProps.brokers" :isAllFields="false"
                :fields="smallCompareProps.fields"></side-to-side-compare-columns>
            </template>
            <template #openAccount="openAccountProps">
              <BrokerListElement :broker="loadBroker(openAccountProps.slug)" :index="0"
                :fields="openAccountProps.fields || fields" :linkSuffix="openAccountProps.linkSuffix" />
            </template>
          </component>
        </div>
        <article class="message is-info" v-show="isMessageVisible">
          <div class="message-header">
            <p>Tylko w JakiBroker.pl</p>
            <button class="delete" aria-label="delete" @click="isMessageVisible = !isMessageVisible"></button>
          </div>
          <div class="message-body">
            Poniżej znajdziesz szczegółowe porównianie brokerów {{ titleEnumerator("", ", ", " oraz ", "") }}. Elementy
            <span class="has-fancy-underline has-text-weight-bold">pogrubione i podkreślone na niebiesko</span>
            zaznaczają najważniejsze dla ciebie cechy, które wybrałeś w filtrach
            w
            <a @click="goToBrokerList">Porównywarce Brokerów</a>.
          </div>
        </article>
        <side-to-side-compare-columns :brokers="this.$route.params.slugs.split('-vs-')"></side-to-side-compare-columns>
      </div>
      <div class="column is-3"></div>
    </div>
    <div class="columns">
      <div class="column is-2"></div>
      <div class="column">
        <div>
          <div class="columns">
            <div class="column content is-full-width has-text-centered">
              <h3 class="has-fancy-underline">
                Zobacz poniższe rankingi JakiBroker.pl
              </h3>
            </div>
          </div>
          <div class="columns">
            <RankingWidget class="column is-half" :ranking="suggestedRanking1"></RankingWidget>
            <RankingWidget class="column is-half" :ranking="suggestedRanking2"></RankingWidget>
          </div>
        </div>
      </div>
      <div class="column is-2"></div>
    </div>
  </div>
</template>

<script>
import SideToSideCompareColumns from "./SideToSideCompareColumns";
import RankingWidget from "./RankingWidget";
import BrokerListElement from "./BrokerListElement";

export default {
  name: "Compare",
  data() {
    return {
      brokers: [],
      slugs: [],
      isMessageVisible: true,
    };
  },
  computed: {
    metaTitle() {
      return this.titleEnumerator("Co lepsze? ", ", ", " czy ", "?");
    },
    metaDescription() {
      return `Szczegółowe porównanie brokerów ${this.titleEnumerator("", ", ", " i ", "")}`;
    },
    metaImageSrc() {
      // TODO
      return "";
    },
    metaUrlContent() {
      return `https://jakibroker.pl/porownaj/${this.slugs.join('-vs-')}/ `;
    },
    comparisonLoader() {
      // it's here just to invoke reactivity
      // this.$route.params.slug1;
      // this.$route.params.slug2;
      // let slugs = [this.$route.params.slug1, this.$route.params.slug2];
      let slugs = this.$route.params.slugs.split("-vs-")
      slugs.sort();
      return () => import(`@/comparisons/${slugs.join('-')}.md`);
    },
    canonicalUrl() {
      let slugs = [this.$route.params.slug1, this.$route.params.slug2];
      slugs.sort();
      const path = this.$router.resolve({
        name: "compare",
        params: { slug1: slugs[0], slug2: slugs[1] },
      }).href;
      return `https://jakibroker.pl${path}`;
    },
    suggestedRanking1() {
      return this.$store.state.rankings[1];
    },
    suggestedRanking2() {
      return this.$store.state.rankings[3];
    },
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },
        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        { property: "og:image", content: this.metaImageSrc },
        { property: "og:image:url", content: this.metaImageSrc },
        { property: "og:image:width", content: 1920 },
        { property: "og:image:height", content: 1080 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "%s",
      link: [
        { vmid: "rel-canonical", rel: "canonical", href: this.canonicalUrl },
      ],
    };
  },
  methods: {
    titleEnumerator(prefix, sep, lastSep, suffix) {
      return `${prefix}${this.brokers.slice(
        0, -1
      ).map((broker) => broker.name).join(sep)}${lastSep}${this.brokers[this.brokers.length - 1].name}${suffix}`;
    },
    createBrokerSchema(broker, index) {
      return {
        "@type": "ListItem",
        "position": index,
        "item": {
          "@type": "FinancialService",
          "name": broker.name,
          "address": {
            "@type": "PostalAddress",
            "addressRegion": broker.countryOfOrigin
          },
          "logo": this.getFullUrlBrokerImage(broker),
          "description": broker.description,
          "serviceType": ["Investment", "Trading"],
          // "aggregateRating": {
          //   "@type": "AggregateRating",
          //   "ratingValue": broker.rate.toString()
          // },
          "offers": {
            "@type": "Offer",
            "priceCurrency": broker.currencies.join(', '),
            "price": broker.minInstallment.replace(/[^0-9.]/g, '') // Removes non-numeric characters for the price
          },
          "areaServed": broker.countryOfRegulation.map(country => ({
            "@type": "Country",
            "name": country
          })),
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Trading Instruments",
            "itemListElement": broker.instruments.map(instrument => ({
              "@type": "OfferCatalog",
              "name": instrument
            }))
          },
          "feesAndCommissionsSpecification": broker.commission,
          "isRelatedTo": {
            "@type": "Product",
            "name": broker.platforms.join(', ')
          },
          "disambiguatingDescription": broker.disclaimer
        }
      };
    }
  },
  jsonld() {
    return {
      "@context": "http://schema.org",
      "@type": "ItemList",
      "itemListElement": [
        this.createBrokerSchema(
          this.loadBroker(this.$route.params.slug1), 1
        ),
        this.createBrokerSchema(
          this.loadBroker(this.$route.params.slug2), 2
        )
      ]
    };
  },
  created() {
    let slugs = this.$route.params.slugs.split("-vs-")
    slugs.sort();
    this.slugs = slugs
    this.brokers = slugs.map(this.loadBroker)

    this.$store.commit(
      "setCurrentPageTitle",
      this.brokers.map((broker) => broker.name).join(" vs ")
    );
  },
  components: {
    SideToSideCompareColumns,
    RankingWidget,
    BrokerListElement
  },
};
</script>
