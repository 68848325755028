<template>
  <div>
    <section
      id="form"
      class="has-form-background overflow-x-hidden overflow-y-hidden has-fancy-border-bottom"
      v-if="$route.name == 'landing'"
    >
      <div class="columns">
        <div class="column is-one-third-tablet"></div>
        <div class="column box box-centered">
          <h2 class="title has-fancy-underline has-text-centered">
            <template v-if="stage == 4 && $route.name !== 'brokerlist'">
              <span>Wybraliśmy dla ciebie brokerów</span>
              <span style="color: #3273dc">!</span>
            </template>
            <template v-else>
              <span>Pomożemy Ci wybrać brokera</span>
              <span style="color: #3273dc">.</span>
            </template>
          </h2>
          <div v-if="stage == 0" class="has-text-centered" :key="1">
            <div>
              <h2 class="subtitle is-4">Chcę handlować</h2>
              <nav class="level">
                <div class="level-item has-text-centered security-clickable">
                  <div @click="nextQuestion('Forex')">
                    <p class="title is-1 has-text-info">
                      <lord-icon
                        animation="hover"
                        params="70"
                        palette="#121331;#3273dc"
                        src="/lordicon/300-coins-euro-outline.json"
                      ></lord-icon>
                    </p>
                    <p class="heading">Walutami</p>
                  </div>
                </div>
                <div class="level-item has-text-centered security-clickable">
                  <div @click="nextQuestion('Akcje')">
                    <p class="title is-1 has-text-info">
                      <lord-icon
                        animation="hover"
                        params="70"
                        palette="#121331;#3273dc"
                        src="/lordicon/403-museum-outline.json"
                      ></lord-icon>
                    </p>
                    <p class="heading">Akcjami</p>
                  </div>
                </div>
                <div class="level-item has-text-centered security-clickable">
                  <div @click="nextQuestion('Surowce')">
                    <p class="title is-1 has-text-info">
                      <lord-icon
                        animation="hover"
                        params="70"
                        palette="#121331;#3273dc"
                        src="/lordicon/413-money-bag-outline.json"
                      ></lord-icon>
                    </p>
                    <p class="heading">Surowcami</p>
                  </div>
                </div>
                <div class="level-item has-text-centered security-clickable">
                  <div @click="nextQuestion('Kryptowaluty')">
                    <p class="title is-1 has-text-info">
                      <lord-icon
                        animation="hover"
                        params="70"
                        palette="#121331;#3273dc"
                        src="/lordicon/296-coin-btc-outline.json"
                      ></lord-icon>
                    </p>
                    <p class="heading">Kryptowalutami</p>
                  </div>
                </div>
              </nav>
              <a class="is-link" @click="nextQuestion(undefined)"
                >pomiń pytanie</a
              >
            </div>
          </div>
          <question
            class="animated fadeIn"
            v-if="stage == 1"
            :key="1"
            :question="questions[1]"
            :nextHandler="nextQuestion"
            :prevHandler="prevQuestion"
          ></question>
          <question
            class="animated fadeIn"
            v-if="stage == 2"
            :key="2"
            :question="questions[2]"
            :nextHandler="nextQuestion"
            :prevHandler="prevQuestion"
          ></question>
          <question
            class="animated fadeIn"
            v-if="stage == 3"
            :key="3"
            :question="questions[3]"
            :nextHandler="nextQuestion"
            :prevHandler="prevQuestion"
          ></question>
          <div
            class="has-text-centered"
            v-if="stage == 4 && $route.name !== 'brokerlist'"
          >
            <a
              class="
                    button
                    is-medium is-info is-rounded
                    cta-button
                    is-outlined
                  "
              @click="goToBrokerList"
            >
              <span>ZOBACZ</span>
              <span class="icon">
                <font-awesome-icon
                  class="
                        clickable-icon
                        has-text-link
                        animated
                        tada
                        infinite
                      "
                  icon="arrow-circle-right"
                ></font-awesome-icon>
              </span>
            </a>
          </div>
          <hr />
          <ul class="steps is-small padding-top-1rem">
            <li
              class="step-item is-info"
              :class="{ 'is-completed': stage > 0, 'is-active': stage == 0 }"
            >
              <div class="step-marker">
                <span
                  class="icon"
                  v-if="stage > 0"
                  :class="{ 'animated jackInTheBox slow': stage > 0 }"
                >
                  <font-awesome-icon icon="check"></font-awesome-icon>
                </span>
                <span v-else>1</span>
              </div>
              <div class="step-details">
                <p class="step-title">Instrument</p>
                <p v-if="answers[0] !== null" class="animated fadeIn">
                  {{ answers[0] }}
                </p>
              </div>
            </li>
            <li
              class="step-item is-info"
              :class="{ 'is-completed': stage > 1, 'is-active': stage == 1 }"
            >
              <div class="step-marker">
                <span
                  class="icon"
                  v-if="stage > 1"
                  :class="{ 'animated jackInTheBox slow': stage > 1 }"
                >
                  <font-awesome-icon icon="check"></font-awesome-icon>
                </span>
                <span v-else>2</span>
              </div>
              <div class="step-details">
                <p class="step-title">Doświadczenie</p>
                <p v-if="answers[1] !== null" class="animated fadeIn">
                  {{ answers[1] }}
                </p>
              </div>
            </li>
            <li
              class="step-item is-info"
              :class="{ 'is-completed': stage > 2, 'is-active': stage == 2 }"
            >
              <div class="step-marker">
                <span
                  class="icon"
                  v-if="stage > 2"
                  :class="{ 'animated jackInTheBox slow': stage > 2 }"
                >
                  <font-awesome-icon icon="check"></font-awesome-icon>
                </span>
                <span v-else>3</span>
              </div>
              <div class="step-details">
                <p class="step-title">Platforma</p>
                <p v-if="answers[2] !== null" class="animated fadeIn">
                  {{ answers[2] }}
                </p>
              </div>
            </li>
            <li
              class="step-item is-info"
              :class="{ 'is-completed': stage > 3, 'is-active': stage == 3 }"
            >
              <div class="step-marker">
                <span
                  class="icon"
                  v-if="stage > 3"
                  :class="{ 'animated jackInTheBox slow': stage > 3 }"
                >
                  <font-awesome-icon icon="check"></font-awesome-icon>
                </span>
                <span v-else>4</span>
              </div>
              <div class="step-details">
                <p class="step-title">Styl</p>
                <p v-if="answers[3] !== null" class="animated fadeIn">
                  {{ answers[3] }}
                </p>
              </div>
            </li>
          </ul>
          <div class="is-pulled-right" v-if="stage > 0">
            <a @click="resetForm">zacznij od początku</a>
          </div>
        </div>
        <div class="column is-one-third-desktop"></div>
      </div>
    </section>
    <section class="section padding-bottom-0" v-show="$route.name != 'landing'">
      <div class="columns">
        <div class="column is-2"></div>
        <div class="column">
          <breadcrump></breadcrump>
        </div>
        <div class="column is-2"></div>
      </div>
    </section>
    <router-view></router-view>
  </div>
</template>

<script>
import Question from "./Question";
import Breadcrump from "./Breadcrump";

export default {
  name: "HomePage",
  data() {
    return {
      compare: [],
      showFields: false,
    };
  },
  watch: {
    stage(newValue) {
      if (newValue == 4) {
        this.$store.commit("setFilter", {
          key: "instrument",
          value: this.answers[0],
        });
        this.$store.commit(
          "setViewMode",
          {
            początkujący: "beginner",
            średniozaawansowany: "intermediate",
            zaawansowany: "advanced",
          }[this.answers[1]]
        );

        this.$store.commit("setFilter", {
          key: "platform",
          value: this.answers[2],
        });
        this.$router.push({ name: "brokerlist", hash: "#brokerList" });
      }
    },
  },
  methods: {
    goToBrokerList() {
      this.$router.push({ name: "brokerlist", hash: "#brokerList" });
    },
    resetForm() {
      this.$gtag.event("resetForm", {
        stage: this.stage,
      });
      this.$store.commit("resetForm");
    },
    nextQuestion(value) {
      this.$gtag.event("questionAnswered", {
        stage: this.stage,
        answer: value,
      });
      this.$store.dispatch("nextQuestion", { value });
    },
    prevQuestion(value) {
      this.$gtag.event("questionPrevious", {
        stage: this.stage,
      });
      this.$store.dispatch("prevQuestion", { value });
    },
    setAnswer(stage, value) {
      this.$store.commit("setAnswer", { stage, value });
    },
  },
  computed: {
    stage() {
      return this.$store.state.stage;
    },
    answers() {
      return this.$store.state.answers;
    },
    questions() {
      return this.$store.state.questions;
    },
    filter() {
      return this.$store.state.filter;
    },
  },
  components: {
    Question,
    Breadcrump,
  },
};
</script>

<style scoped>
.security-clickable {
  cursor: pointer;
}

lord-icon {
  width: 96px;
  height: 96px;
}

lord-icon .big-icon {
  width: 148px;
  height: 148px;
}
.has-form-background {
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}
.box-centered {
  padding: 2rem;
}
@media only screen and (max-width: 768px) {
  .has-form-background {
    background: none;
  }
  .box-centered {
    z-index: 100;
  }
}
@media only screen and (min-width: 769px) {
  .box-centered {
    position: absolute;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .has-form-background {
    height: 70vh;
    background: url("https://images.unsplash.com/photo-1423666523292-b458da343f6a?auto=format&fit=crop&w=3000&q=60&duotone=121331,3273dc&duotone-alpha=70")
      no-repeat fixed center;
    width: 100%;
  }
}
/* ?auto=format&fit=crop&w=3000&duotone=121331,3273dc&duotone-alpha=70 */
</style>
