<template>
  <nav class="navbar is-fixed-top has-fancy-border-bottom has-background-warm-white">
    <div class="container has-text-centered has-background-warm-white">
      <div class="navbar-brand has-background-warm-white">
        <div class="navbar-item">
          <h1 class="title is-size-4">
            <a href="/">
              <img style="vertical-align: middle" src="/favicon-310.png" alt="Jakiego brokera FOREX wybrać?" />&nbsp;
              <span style="color: #363636" class="has-fancy-underline">JakiBroker</span>
              <span style="color: #3273dc" class="has-fancy-underline">.</span>
              <span style="color: #363636" class="has-fancy-underline">pl</span>
            </a>
          </h1>
        </div>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
          @click="isActiveMenu = !isActiveMenu" :class="{ 'is-active': isActiveMenu }">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu navbar-end has-background-warm-white" :class="{ 'is-active': isActiveMenu }">
        <div class="navbar-item">
          <router-link :to="{ name: 'brokerlist' }">
            <button class="button is-light">
              <strong>Porównaj Brokerów</strong>
              <span class="icon">
                <lord-icon animation="hover" target="button" params="200" palette="#3e8dd0;#ffff00"
                  src="/lordicon/237-star-rating-morph-edited.json"></lord-icon>
              </span>
            </button>
          </router-link>
        </div>
        <div class="navbar-item">
          <router-link :to="{ name: 'rankinglist' }">Wszystkie rankingi</router-link>
        </div>
        <div class="navbar-item">
          <router-link :to="{ name: 'dictionarylist' }">Słownik inwestycyjny</router-link>
        </div>
        <div class="navbar-item">
          <router-link :to="{ name: 'articlelist' }">Baza wiedzy</router-link>
        </div>
        <div class="navbar-item">
          <router-link :to="{ name: 'exchanges' }">Godziny otwarcia giełd</router-link>
        </div>
        <div class="navbar-item">
          <router-link :to="{ name: 'aboutus' }">O nas i kontakt</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
lord-icon {
  width: 50px;
  height: 50px;
}

.navbar-item.center {
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
</style>

<script>
export default {
  data() {
    return {
      isActiveMenu: false,
    };
  },
  watch: {
    $route() {
      this.isActiveMenu = false;
    },
  },
};
</script>
