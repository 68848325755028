<template>
  <router-link :to="{ name: 'article', params: { slug: article.slug } }">
    <div class="box article-title">
      <h3>{{ article.title }}</h3>
      <small>{{ article.publishDate }}</small>
      <router-link :to="{ name: 'article', params: { slug: article.slug } }">
        <picture>
          <source type="image/webp" :srcset="article.coverImageSrcWebp" />
          <img :src="article.coverImageSrcJpg" :alt="article.coverImageAlt" />
        </picture>
      </router-link>
      <p>{{ article.teaser }}</p>
      <router-link :to="{ name: 'article', params: { slug: article.slug } }">
        <a class="button is-info is-fullwidth wrap-button-text">
          <span>
            {{ article.teaserLink }}
          </span>
          <span class="icon">
            <font-awesome-icon icon="arrow-circle-right"></font-awesome-icon>
          </span>
        </a>
      </router-link>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["article"],
};
</script>

<style scoped>
.article-title {
  padding: 1.5rem;
}
</style>
