<template>
    <div class="column is-one-quarter has-borders-column">
        <span class="heading-inline">Instrumenty</span>
        <br />
        <div class="tags">
            <template v-if="broker.onlyCFD">
                <span class="tag" :class="{
                'is-link is-light':
                    selectedInstrument &&
                    instrument.toLowerCase() ==
                    selectedInstrument.toLowerCase(),
            }" v-for="instrument in broker.instruments" :key="instrument">{{ instrument }} (CFD)</span>
            </template>
            <template v-else>
                <span class="tag" :class="{
                'is-link is-light':
                    selectedInstrument &&
                    instrument.toLowerCase() ==
                    selectedInstrument.toLowerCase(),
            }" v-for="instrument in broker.instruments" :key="instrument">{{ instrument }}</span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
        selectedInstrument: {
            type: String,
            required: false,
        },
    },
};
</script>