import Vue from "vue";
import VueRouter from "vue-router";

import AboutUs from "./components/AboutUs";
import ArticleList from "./components/ArticleList";
import ArticleTemplate from "./components/ArticleTemplate";
import BrokerDetail from "./components/BrokerDetail";
import BrokerList from "./components/BrokerList";
import RankingList from "./components/RankingList";
import Compare from "./components/Compare";
import DictionaryDefinitionTemplate from "./components/DictionaryDefinitionTemplate";
import DictionaryList from "./components/DictionaryList";
import HomePage from "./components/HomePage";
import Landing from "./components/Landing";
import Sitemap from "./components/Sitemap";
import Exchanges from "./components/Exchanges";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    pathToRegexpOptions: { strict: true },
    meta: {
      name: "Strona Główna.",
    },
    children: [
      {
        path: "/",
        name: "landing",
        component: Landing,
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "/mapa-strony/",
        name: "sitemap",
        component: Sitemap,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "Mapa strony",
        },
      },
      {
        path: "/godziny-otwarcia-gield/",
        name: "exchanges",
        component: Exchanges,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "Godziny otwarcia giełd",
        },
      },
      {
        path: "/brokerzy/",
        name: "brokerlist",
        component: BrokerList,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "Porównaj Brokerów",
        },
        children: [
          {
            path: "/brokerzy/:slug+/",
            name: "brokerdetail",
            component: BrokerDetail,
            pathToRegexpOptions: { strict: true },
            meta: {
              name: null,
            },
          },
        ],
      },
      {
        path: "/ranking/",
        name: "rankinglist",
        component: RankingList,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "Ranking Brokerów",
        },
        children: [
          {
            path: "/ranking/:slug+/",
            name: "brokerranking",
            component: BrokerList,
            pathToRegexpOptions: { strict: true },
            meta: {
              name: null,
            },
          },
        ],
      },

      {
        path: "/porownaj/",
        name: "compare-root",
        component: Compare,
        pathToRegexpOptions: { strict: true },
        redirect: (to) => {
          return "brokerzy/";
        },
        meta: {
          name: "Porównaj Brokerów",
        },
        children: [
          {
            // path: "/porownaj/:slug1+-vs-:slug2+/",
            path: "/porownaj/:slugs+/",
            name: "compare",
            component: Compare,
            pathToRegexpOptions: { strict: true },
            meta: {
              name: null,
            },
          },
        ],
      },
      {
        path: "/bazawiedzy/",
        name: "articlelist",
        component: ArticleList,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "Baza Wiedzy",
        },
        children: [
          {
            path: ":slug+/",
            name: "article",
            component: ArticleTemplate,
            pathToRegexpOptions: { strict: true },
            meta: {
              // this means the name should be taken from vuex
              name: null,
            },
          },
        ],
      },
      {
        path: "/slownikpojec/",
        name: "dictionarylist",
        component: DictionaryList,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "Słownik Inwestycyjny",
        },
        children: [
          {
            path: ":slug+/",
            name: "definition",
            component: DictionaryDefinitionTemplate,
            pathToRegexpOptions: { strict: true },
            meta: {
              // this means the name should be taken from vuex
              name: null,
            },
          },
        ],
      },
      {
        path: "/o-nas/",
        name: "aboutus",
        component: AboutUs,
        pathToRegexpOptions: { strict: true },
        meta: {
          name: "O nas i kontakt",
        },
      },
    ],
  },
];

var router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = { x: 0, y: 0 };
    if (savedPosition) {
      position = savedPosition;
    }

    if (to.hash) {
      const element = document.querySelector(to.hash);
      if (element) {
        if (to.hash == "#brokerList") {
          setTimeout(() => {
            window.scrollTo({
              top: element.offsetTop,
              behavior: "smooth",
            });
          }, 750);
        } else {
          window.scrollTo({
            top: element.offsetTop,
            behavior: "smooth",
          });
        }
      }
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(position);
        }, 1);
      });
    }
  },
});

export default router;
