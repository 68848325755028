<template>
    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false"
        :class="{
            'has-background-info-light': sorting == sortingOptions.biggestInterestRate,
        }">
        <span class="heading-inline">Oprocentowanie wolnych środków</span>
        <span @click.stop.prevent="showInfoModalWith('interestRate')">
            <font-awesome-icon v-show="mouseOver || isMobile()" class="clickable-icon has-text-link animated tada"
                icon="question"></font-awesome-icon>
        </span>
        <br />
        <template v-if="broker.interestRate.length">
            <ul class="features-list">
                <li v-for="interestRate in broker.interestRate" :key="interestRate">
                    <small>{{ interestRate }}</small>
                </li>
            </ul>
        </template>
        <template v-else>
            <span class="icon has-text-danger">
                <font-awesome-icon icon="times-circle"></font-awesome-icon> </span>Brak
        </template>
    </div>
</template>

<script>
import { sortingOptions } from '../BrokerList.constants';

export default {
    data() {
        return {
            mouseOver: false,
            sortingOptions,
        };
    },
    props: {
        broker: {
            type: Object,
            required: true,
        },
        sorting: {
            type: String,
            required: false,
        },
    },
};
</script>