<template>
    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false">
        <span class="heading-inline">Platforma</span>
        <span @click.stop.prevent="showInfoModalWith('platform')">
            <font-awesome-icon v-show="mouseOver || isMobile()" class="clickable-icon has-text-link animated tada"
                icon="question"></font-awesome-icon>
        </span>
        <br />
        <ul class="features-list">
            <li v-for="platform in broker.platforms" :key="platform">
                <small :class="{
        'has-fancy-underline has-text-weight-bold':
            selectedPlatform &&
            platform.toLowerCase() ==
            selectedPlatform.toLowerCase(),
    }">{{ platform }}</small>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
        selectedPlatform: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            mouseOver: false
        };
    },
};

</script>