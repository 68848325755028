<template>
    <div class="column is-one-quarter has-borders-column" :class="{
        'has-background-info-light':
            sorting == sortingOptions.lowestMinimumInstallment,
    }">
        <span class="heading-inline">wpłata minimalna</span>
        <br />
        <small>{{ broker.minInstallment }}</small>
    </div>
</template>

<script>
import { sortingOptions } from '../BrokerList.constants';


export default {
    data() {
        return {
            sortingOptions,
        };
    },
    props: {
        broker: {
            type: Object,
            required: true,
        },
        sorting: {
            type: String,
            required: true,
        },
    },
};
</script>