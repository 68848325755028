<template>
  <section class="section">
    <div v-if="$route.name == 'rankinglist'" class="columns" id="rankingList">
      <div class="column is-3"></div>
      <div class="column">
        <h1 class="title has-fancy-underline has-text-centered">
          <span>Rankingi brokerów JakiBroker.pl</span>
        </h1>
      </div>
      <div class="column is-3"></div>
    </div>
    <div v-if="$route.name == 'rankinglist'" class="columns">
      <div class="column is-2"></div>
      <div class="column content">
        <div class="columns is-multiline">
          <RankingWidget class="column is-half" v-for="ranking in rankings" :key="ranking.slug" :ranking="ranking">
          </RankingWidget>
        </div>
      </div>

      <div class="column is-2"></div>
    </div>
    <router-view v-if="$route.name == 'brokerranking'"></router-view>
  </section>
</template>

<script>
import RankingWidget from "./RankingWidget";

export default {
  computed: {
    rankings() {
      return this.$store.getters.getRankingList;
    },
  },
  components: {
    RankingWidget,
  },
};
</script>
