<template>
    <div class="column is-one-quarter has-borders-column">
        <span class="heading-inline">kraj pochodzenia</span>

        <br />
        <span class="flag-icon" :class="flags[broker.countryOfOrigin.toLowerCase()]"></span>&nbsp;
        <small>{{ broker.countryOfOrigin }}</small>
    </div>

</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
    },
}
</script>
