<template>
    <div class="column is-one-quarter has-borders-column">
        <span class="heading-inline">kraj regulacji</span>
        <br />

        <small>
            <span v-for="(countryOfRegulation,
                index) in broker.countryOfRegulation" :key="countryOfRegulation">
                <span :class="{
                'has-fancy-underline has-text-weight-bold':
                    selectedCountryOfRegulation &&
                    countryOfRegulation.toLowerCase() ==
                    selectedCountryOfRegulation.toLowerCase(),
            }">{{ countryOfRegulation }}</span>
                <span v-if="index != broker.countryOfRegulation.length - 1">,&nbsp;</span>
            </span>
        </small>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true
        },
        selectedCountryOfRegulation: {
            type: String,
            required: false
        },
    }
};
</script>