<template>
  <div>
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column has-text-centered">
        <h1 class="title has-fancy-underline">Godziny otwarcia giełd</h1>
      </div>
      <div class="column is-3"></div>
    </div>
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column">
        <div class="content has-text-justified">
          Giełdy są jednym z najważniejszych miejsc handlowych, gdzie inwestorzy
          mogą kupować i sprzedawać akcje, obligacje, surowce i inne instrumenty
          finansowe. Każda giełda ma swoje unikalne godziny otwarcia, które
          zależą od strefy czasowej, w której się znajduje. Warto wiedzieć, że
          giełdy nie są czynne przez całą dobę i mają określone dni wolne, w
          których nie odbywa się handel. W poniższej liście przedstawiamy
          godziny otwarcia najważniejszych giełd świata na których handluje się
          akcjami. <strong>Wszystkie godziny podane są w czasie polskim.</strong>
        </div>
        <article class="margin-top-1rem margin-bottom-1rem has-text-centered"
          v-if="isFeatureEnabled('XTB_MASTERCLASS')">
          <a href="/open/xtb-masterclass/">
            <img src="/img/banners/xtb-masterclass.jpg" />
          </a>
        </article>
        <button class="button is-fullwidth" :class="{ 'is-link': highlightCurrentlyOpened }" @click="highlight">
          Podświetl aktualnie otwarte
        </button>
        <table class="table is-hoverable is-stripped is-fullwidth margin-top-1rem">
          <thead>
            <tr>
              <th>
                <span class="icon-text">
                  <span>Giełda</span>
                  <span class="icon has-text-grey-light">
                    <font-awesome-icon class="clickable-icon"
                      :class="{ 'has-text-link': sortingOrder == 'asc' && sorting == 'name' }"
                      @click="setSorting('name', 'asc')" icon="arrow-down"></font-awesome-icon>
                  </span>
                  <span class="icon has-text-grey-light">
                    <font-awesome-icon class="clickable-icon"
                      :class="{ 'has-text-link': sortingOrder == 'desc' && sorting == 'name' }"
                      @click="setSorting('name', 'desc')" icon="arrow-up"></font-awesome-icon>
                  </span>
                </span>

              </th>
              <th>
                <span class="icon-text">
                  <span>Kraj</span>
                  <span class="icon has-text-grey-light">
                    <font-awesome-icon class="clickable-icon"
                      :class="{ 'has-text-link': sortingOrder == 'asc' && sorting == 'country' }"
                      @click="setSorting('country', 'asc')" icon="arrow-down"></font-awesome-icon>
                  </span>
                  <span class="icon has-text-grey-light">
                    <font-awesome-icon class="clickable-icon"
                      :class="{ 'has-text-link': sortingOrder == 'desc' && sorting == 'country' }"
                      @click="setSorting('country', 'desc')" icon="arrow-up"></font-awesome-icon>
                  </span>
                </span>

              </th>
              <th>Godziny otwarcia</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="exchange in exchanges" :key="exchange.name">
              <td :class="{
                'is-selected':
                  highlightCurrentlyOpened && exchange.currentlyOpened,
                'is-info': $route.query.show == exchange.name,
              }">
                {{ exchange.name }}
              </td>
              <td :class="{
                'is-selected':
                  highlightCurrentlyOpened && exchange.currentlyOpened,
              }">
                <span class="flag-icon" :class="'flag-icon-' + exchange.flag"></span>
                {{ exchange.country }}
              </td>
              <td :class="{
                'is-selected':
                  highlightCurrentlyOpened && exchange.currentlyOpened,
              }">
                {{ exchange.open }} - {{ exchange.close }}
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div class="columns">
            <div class="column content is-full-width">
              <component :is="articleLoaderExchanges" key="articleExchanges"></component>
            </div>
          </div>
          <div class="columns">
            <div class="column content is-full-width has-text-centered">
              <h3 class="has-fancy-underline">
                A teraz zobacz gdzie możesz handlować akcjami
              </h3>
            </div>
          </div>
          <div class="columns">
            <RankingWidget class="column" :ranking="stocksRanking"></RankingWidget>
          </div>
        </div>
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<script>
import exchanges from "@/exchanges.json";
import RankingWidget from "./RankingWidget.vue";


const sortingType = {
  name: "name",
  country: "country",
};

const sortingOrder = {
  asc: "asc",
  desc: "desc",
};

export default {
  name: "Exchanges",
  components: {
    RankingWidget,
  },
  data() {
    return {
      highlightCurrentlyOpened: false,
      sorting: sortingType.name,
      sortingOrder: sortingOrder.asc,
    };
  },
  methods: {
    setSorting(type, order) {
      this.sorting = type;
      this.sortingOrder = order;
    },
    isTimeInRange(time, startTime, endTime) {
      const [timeHours, timeMinutes] = time.split(":").map(Number);
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      const [endHours, endMinutes] = endTime.split(":").map(Number);

      // Convert all times to minutes for easier comparison
      const timeInMinutes = timeHours * 60 + timeMinutes;
      const startInMinutes = startHours * 60 + startMinutes;
      const endInMinutes = endHours * 60 + endMinutes;

      // Check if time is within range
      return timeInMinutes >= startInMinutes && timeInMinutes <= endInMinutes;
    },
    highlight() {
      this.highlightCurrentlyOpened = !this.highlightCurrentlyOpened;
      this.$gtag.event("highlightCurrentlyOpenExchanges", {
        value: this.highlightCurrentlyOpened,
      });
    },
  },
  computed: {
    exchanges() {
      const sorted = exchanges.sort((a, b) => {
        if (this.sortingOrder === sortingOrder.asc) {
          return a[this.sorting].localeCompare(b[this.sorting]);
        } else {
          return b[this.sorting].localeCompare(a[this.sorting]);
        }
      });
      sorted.forEach((e) => {
        e.currentlyOpened = this.isTimeInRange(
          this.currentTime,
          e.open,
          e.close
        );
      });
      return sorted;
    },
    articleLoaderExchanges() {
      return () => import(`@/articles/godziny-otwarcia-gield.md`);
    },
    currentTime() {
      const nowDate = new Date();
      return `${nowDate.getHours()}:${nowDate.getMinutes()}`;
    },
    suggestedRanking1() {
      return this.$store.state.rankings[1];
    },
    suggestedRanking2() {
      return this.$store.state.rankings[2];
    },
    stocksRanking() {
      return this.$store.state.rankings.find((r) => r.slug == "brokerzy-z-dostepem-do-akcji");
    },
  },
};
</script>
