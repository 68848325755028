export const sortingOptions = {
    alphabetically: 'alphabetically',
    lowestMinimumInstallment: 'lowestMinimumInstallment',
    lowestSpread: 'lowestSpread',
    highestLeverage: 'highestLeverage',
    biggestInterestRate: 'biggestInterestRate',
    popularity: 'popularity',
    suggested: 'suggested',
}

export const sortingLabels = {
    [sortingOptions.alphabetically]: 'Alfabetycznie',
    [sortingOptions.lowestMinimumInstallment]: 'Najniższa minimalna wpłata',
    [sortingOptions.lowestSpread]: 'Najniższy spread',
    [sortingOptions.highestLeverage]: 'Największa dźwignia',
    [sortingOptions.biggestInterestRate]: 'Najwyższe oprocentowanie wolnych środków',
    [sortingOptions.popularity]: 'Popularność',
    [sortingOptions.suggested]: 'Sugerowane',
}

