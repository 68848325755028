<template>
    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false">
        <span class="heading-inline">Ochrona przed ujemnym saldem</span>
        <span @click.stop.prevent="
        showInfoModalWith('investmentProtection')
        ">
            <font-awesome-icon v-show="mouseOver || isMobile()" class="clickable-icon has-text-link animated tada"
                icon="question"></font-awesome-icon>
        </span>
        <br />

        <small>{{ broker.investmentProtection }}</small>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            mouseOver: false
        };
    },
};
</script>