<template>
    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false"
        :class="{
            'has-background-info-light': sorting == sortingOptions.highestLeverage,
        }">
        <span class="heading-inline">Dźwignia</span>
        <span class="has-tooltip-multiline" @click.stop.prevent="showInfoModalWith('leverage')">
            <font-awesome-icon v-show="mouseOver || isMobile()" class="clickable-icon has-text-link animated tada"
                icon="question"></font-awesome-icon>
        </span>
        <br />

        <span>{{ broker.leverage }}</span>
    </div>
</template>

<script>
import { sortingOptions } from '../BrokerList.constants'
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
        sorting: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            mouseOver: false,
            sortingOptions,
        };
    },
};
</script>