<template>
  <div class="card has-background-warm-white">
    <div class="card-content">
      <div class="columns is-vcentered is-centered">
        <div class="column">
          <figure class="container image is-128x128">
            <img :src="broker1.imageSrc" />
          </figure>
        </div>
        <div class="column">
          <figure class="container image is-128x128">
            <img :src="broker2.imageSrc" />
          </figure>
        </div>
      </div>
    </div>
    <router-link :to="{ name: 'compare', params: { slugs: [broker1.slug, broker2.slug].join('-vs-') } }">
      <footer class="card-footer">
        <p class="card-footer-item">
          <span>Zobacz porównanie {{ broker1.name }} czy {{ broker2.name }}.</span>
        </p>
      </footer>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["broker1", "broker2"],
};
</script>
