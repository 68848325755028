<template>
  <div class="modal" :class="{ 'is-active': showInfoModal }">
    <div class="modal-background"></div>
    <div
      class="modal-card"
      :class="{ 'animated zoomIn faster': showInfoModal }"
    >
      <header class="modal-card-head has-background-white is-borderless">
        <p class="modal-card-title">{{ infoModal.title }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeInfoModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <template v-if="infoModal.imgSrc">
          <div class="has-text-centered">
            <img :src="infoModal.imgSrc" alt="noelo" />
          </div>
        </template>
        <template v-else>
          <p class="has-text-justified">{{ infoModal.content }}</p>
          <br />
          <p>
            <a
              v-if="infoModal.link"
              :href="infoModal.link"
              target="_blank"
              rel="noreferrer nofollow noopener"
              >przeczytaj więcej.</a
            >
            <router-link
              v-if="infoModal.to"
              :to="infoModal.to"
              target="_blank"
              rel="noreferrer noopener"
              >przeczytaj więcej</router-link
            >
          </p>
        </template>
      </section>
      <footer class="modal-card-foot has-background-white is-borderless">
        <button class="button is-info" @click="closeInfoModal">OK</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeInfoModal() {
      this.$store.commit("closeInfoModal");
    },
  },
  computed: {
    showInfoModal() {
      return this.$store.state.showInfoModal;
    },
    infoModal() {
      return this.$store.state.infoModal;
    },
  },
};
</script>
