<template>
    <div class="column is-one-quarter has-borders-column">
        <span class="heading-inline">waluta konta</span>
        <br />
        <small>
            <span v-for="(currency, index) in broker.currencies" :key="currency">
                <span :class="{
                'has-fancy-underline has-text-weight-bold':
                    selectedAccountCurrency &&
                    currency.toLowerCase() ==
                    selectedAccountCurrency.toLowerCase(),
            }">{{ currency }}</span>
                <span v-if="index != broker.currencies.length - 1">,&nbsp;</span>
            </span>
        </small>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
        selectedAccountCurrency: {
            type: String,
            required: false,
        },
    },
};
</script>
