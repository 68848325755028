<template>

    <div class="column is-one-quarter has-borders-column" @mouseover="mouseOver = true" @mouseleave="mouseOver = false">
        <div class="columns" style="margin-bottom: 0">
            <div class="column is-1">
                <figure class="image is-24x24">
                    <img src="/img/logos/logo-kalkulatorgieldowy.png" />
                </figure>
            </div>
            <div class="column">
                <span class="heading-inline">Kalkulator giełdowy</span>
                <span @click.stop.prevent="
        showInfoModalWith('kalkulatorgieldowy')
        ">
                    <font-awesome-icon v-show="mouseOver || isMobile()"
                        class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
                </span>
            </div>
        </div>

        <template v-if="broker.kalkulatorgieldowy">
            <span class="icon has-text-success">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
            </span>
            <a :href="broker.kalkulatorgieldowy">Wspierane. Rozlicz PIT</a>
        </template>
        <template v-else>
            <span class="icon has-text-danger">
                <font-awesome-icon icon="times-circle"></font-awesome-icon> </span>Brak
        </template>
    </div>
</template>

<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            mouseOver: false
        };
    },
};

</script>