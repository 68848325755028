<template>
  <div id="app">
    <NavBar></NavBar>
    <InfoModal></InfoModal>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import InfoModal from "./components/InfoModal";

export default {
  name: "App",
  components: { NavBar, Footer, InfoModal },
  data() {
    return {
      metaDescription: "Najbardziej zaawansowana porównywarka brokerów.",
      metaTitle: "Jakiego brokera wybrać?",
    };
  },
  computed: {
    canonicalUrl() {
      return `https://jakibroker.pl${this.$route.path}`;
    },
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },

        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        // { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        // { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        // { property: "og:image", content: this.metaImageSrc },
        // { property: "og:image:url", content: this.metaImageSrc },
        // { property: "og:image:width", content: 1920 },
        // { property: "og:image:height", content: 1080 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "%s - JakiBroker.pl",
      link: [
        { vmid: "rel-canonical", rel: "canonical", href: this.canonicalUrl },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* margin-top: 60px; */
}

/*
@media all and (max-width: 768px) {
  .main-section {
    padding: 0 !important;
  }
} */
</style>
