<template>
    <div class="card">
        <header>
            <p class="card-header-title is-centered">
                <span>Autor</span>
            </p>
        </header>
        <div class="card-content">
            <div class="media">
                <div class="media-left">
                    <figure class="image is-128x128">
                        <source type="image/webp" :srcset="author.imageWebp" />
                        <img :src="author.imageJpg" :alt="author.name">
                    </figure>
                </div>
                <div class=" media-content">
                    <p class="title is-4">{{ author.name }}
                        <span v-if="author.type == 'GUEST'" class="tag is-info is-light">
                            Gość
                        </span>
                    </p>
                    <p class="subtitle is-6">
                        {{ author.title }}
                    </p>
                    <p class="subtitle is-6">
                        <a :href="author.url" target="_blank" rel="noopener noreferrer">{{ author.url }}</a>
                    </p>
                </div>
            </div>

            <div class="content">
                {{ author.description }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        authorSlug: {
            type: String,
            required: true
        }
    },
    computed: {
        author() {
            return this.$store.state.authors.find(author => author.slug === this.authorSlug);
        }
    }
}
</script>