<template>
  <section class="section">
    <div class="columns" id="aboutUs">
      <div class="column is-3"></div>
      <div class="column content">
        <h1 class="has-fancy-underline has-text-centered">Co to jest JakiBroker.pl?</h1>
        <p>
          <strong>JakiBroker.pl to najbardziej zaawansowana porównywarka ofert brokerów na polskim rynku.</strong>
          Wychodzimy na przeciw ludziom zastanawiającym się jakiego brokera wybrać. Dokładamy wszelkich starań aby
          udostępniane dane były rzetelne i aktualne. Na JakiBroker.pl masz do dyspozycji:
        <ul>
          <li><router-link :to="{ name: 'dictionarylist' }">Słownik pojęć inwestycyjnych</router-link> - każde z terminów
            rozpoczyna się od maksymalnie kilkuzdaniowego wyjaśnienia, żeby oszczędzić twój czas.</li>
          <li><router-link :to="{ name: 'articlelist' }">Baza wiedzy</router-link> - artykuły o tematyce inwestycyjnej,
            odpowiadające na najczęściej zadawane pytania.</li>
          <li><router-link :to="{ name: 'brokerlist' }">Lista Brokerów</router-link> - serce portalu, zaawansowane
            narzędzie
            porównujące oferty brokerów. Możesz na przykład schować informacje o brokerze, które cię nie interesują i
            porównywać tylko te, które akurat są ci potrzebne.</li>
        </ul>
        </p>
        <h2 class="has-fancy-underline has-text-centered">Jak zarabiamy?</h2>
        <p><strong>Celem nadrzędnym JakiBroker.pl jest dostarczenie najwyższej klasy narzędzia, które jest punktem
            startowym każdego inwestującego.</strong></p>
        <p>Chcemy być transparentni w tym co robimy, dlatego informujemy cię, że gdy klikniesz przycisk: </p>
        <div class="has-text-centered padding-bottom-1rem">
          <button class="button is-info" @click="showInfoModalWith('thankyou')">
            <span>ZAŁÓŻ KONTO</span>
            <span class="icon">
              <lord-icon animation="hover" target="button" params="200" palette="#ffffff;#000000"
                src="/lordicon/20-love-heart-outline.json"></lord-icon>
            </span>
          </button>
        </div>
        <p>
          zostaniesz przekierowany do strony www brokera i <strong>jeśli założysz konto i wykonasz odpowiednią liczbę
            transakcji to my dostaniemy wynagrodzenie z tego tytułu.</strong></p>
        <p>
          Mamy podpisane umowy afiliacyjne z następującymy brokerami:
        <ul class="features-list">
          <li>XTB</li>
          <li>XM</li>
          <li>Saxo Bank</li>
          <li>eToro</li>
          <li>Plus500</li>
          <li>Tickmill</li>
        </ul>
        </p>
        <p>Możesz zauważyć, że nie ze wszystkimi brokerami znajdującymi się na JakiBroker.pl mamy podpisane umowy
          afiliacyjne. Naszym najważniejszym celem jest <strong>stworzenie najlepszego narzędzia do porównywania ofert
            brokerów w Polsce</strong>. Nie możemy więc pomijać tych brokerów w naszych zestawieniach. Przeczyłoby to idei
          serwisu.</p>

        <h2 class="has-fancy-underline has-text-centered">Kontakt</h2>
        <p>Możesz się z nami skontaktować pisząc maila na adres <strong>jakibroker@wonsystudio.com</strong>. Jeśli
          zauważysz błąd na stronie, masz pomysł na rozwój JakiBroker.pl lub chcesz podjąć współpracę reklamową, to pisz
          śmiało!</p>

        <p><strong>Uwaga! Nie udzielamy porad inwestycyjnych. Żadna forma kontaktu z nami nie może służyć do udzielania
            porad inwestycyjnych.</strong>
        </p>
      </div>
      <div class="column is-3"></div>
    </div>
  </section>
</template>


<script>
export default {
  data() {
    return {
      metaDescription: "Najbardziej zaawansowana porównywarka brokerów.",
      metaTitle: "Jakiego Brokera wybrać?",
    };
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },

        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        // { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        // { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        // { property: "og:image", content: this.metaImageSrc },
        // { property: "og:image:url", content: this.metaImageSrc },
        // { property: "og:image:width", content: 1920 },
        // { property: "og:image:height", content: 1080 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "JakiBroker.pl - %s",
    };
  },
};
</script>
