<template>
    <div class="column is-one-quarter has-borders-column">
        <span class="heading-inline">Prowizje i opłaty</span>
        <br />
        <ul class="features-list">
            <li v-for="commissionInfo in broker.commission" :key="commissionInfo">
                <small>{{ commissionInfo }}</small>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        broker: {
            type: Object,
            required: true,
        },
    },
};
</script>