<template>
  <nav
    class="breadcrumb has-succeeds-separator padding-bottom-2rem"
    aria-label="breadcrumbs"
  >
    <ul>
      <li>
        <router-link :to="{ name: 'landing' }" class="link"
          >Strona Główna</router-link
        >
      </li>
      <li
        v-for="routeElem in $route.matched.slice(1)"
        :key="routeElem.name"
        :class="{ 'is-active': $route.name == routeElem.name }"
      >
        <router-link class="link" :to="{ name: routeElem.name }">
          <template v-if="routeElem.meta.name">{{
            routeElem.meta.name
          }}</template>
          <template v-else>{{ $store.state.currentPageTitle }}</template>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
};
</script>
