<template>
  <section class="section">
    <div class="columns" v-if="$route.name == 'dictionarylist'">
      <div class="column is-3"></div>
      <div class="column">
        <h1 class="title has-text-centered has-fancy-underline padding-bottom-1rem">
          Słownik inwestycyjny
        </h1>
      </div>
      <div class="column is-3"></div>
    </div>
    <template v-if="$route.name == 'dictionarylist'">
      <div class="columns">
        <div class="column is-3"></div>
        <div class="column">
          <div class="columns is-multiline">
            <div class="column content is-one-third" v-for="(definitions, letter) in dictionary" :key="letter">
              <h2 class="has-fancy-underline">
                <span>{{ letter }}</span>
                <span style="color: #3273dc" class="has-fancy-underline">.</span>
              </h2>
              <ul>
                <li v-for="definition in definitions" :key="definition.slug">
                  <router-link :to="{ name: 'definition', params: { slug: definition.slug } }">{{ definition.title
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="column is-3"></div>
      </div>
    </template>
    <router-view></router-view>
  </section>
</template>

<script>
import definitions from "../definitions.json";

definitions.sort((a, b) => (a.slug > b.slug ? 1 : -1));

export default {
  computed: {
    dictionary() {
      let ret = {};
      for (let def of definitions) {
        const firstLetter = def.title.charAt(0).toUpperCase();
        if (ret[firstLetter] === undefined) {
          ret[firstLetter] = [];
        }
        ret[firstLetter].push(def);
      }
      return ret;
    },
  },
};
</script>
