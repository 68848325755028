<template>
  <div>
    <div class="message-header cursor-click" @click="isOpen = !isOpen">
      <p>{{ question.question }}</p>
      <button class="button is-link">
        <span class="icon">
          <lord-icon animation="hover" target="button" params="200" palette="#ffffff;#000000" :src="isOpen
            ? '/lordicon/34-arrow-up-outline-edited.json'
            : '/lordicon/33-arrow-down-outline-edited.json'
            "></lord-icon>
        </span>
      </button>
    </div>
    <div class="message-body has-text-justified" v-show="isOpen">
      <p>
        {{ question.content }}
      </p>
      <router-link :to="question.link">
        <a class="button is-outlined is-fullwidth">
          <span>
            {{ question.teaser }}
          </span>
          <span class="icon">
            <font-awesome-icon icon="arrow-circle-right"></font-awesome-icon>
          </span>
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question"],
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
