<template>
  <div class="columns">
    <div class="column is-3"></div>
    <div class="column content padding-1rem">
      <h1 class="title">{{ article.title }}</h1>
      <div class="has-text-centered">
        <picture>
          <source type="image/webp" :srcset="article.coverImageSrcWebp" />
          <img :src="article.coverImageSrcJpg" :alt="article.coverImageAlt" />
        </picture>
      </div>
      <div class="columns is-gapless" v-if="article.coverImageSrcJpg">
        <div class="column"></div>
        <div class="column is-2" v-if="article.coverImageAuthor">
          <small>Image by
            <a :href="article.coverImageAuthorLink" rel="noreferrer
          noopener nofollow" target="_blank">{{ article.coverImageAuthor }}</a>
          </small>
        </div>
      </div>
      <component :is="articleLoader" :key="$route.params.slug">
        <template #author v-if="isFeatureEnabled('AUTHOR_BOX')">
          <AuthorBox :authorSlug="article.author" />
        </template>
        <template #openAccount="openAccountProps">
          <BrokerListElement :broker="loadBroker(openAccountProps.slug)" :index="0"
            :fields="openAccountProps.fields || fields" :linkSuffix="openAccountProps.linkSuffix" />
        </template>
        <template #smallCompare="smallCompareProps">
          <side-to-side-compare-columns :brokers="smallCompareProps.brokers" :isAllFields="false"
            :fields="smallCompareProps.fields">
          </side-to-side-compare-columns>
        </template>
      </component>
      <div class="margin-top-1rem">
        <RankingWidget v-if="ranking" :ranking="ranking"></RankingWidget>
        <ArticleWidget v-if="relatedArticle" :article="relatedArticle"></ArticleWidget>
      </div>
      <div class="columns padding-top-2rem has-text-centered" v-if="broker">
        <div class="column">
          <router-link :to="{ name: 'brokerdetail', params: { slug: broker.slug } }">
            <button class="button is-large" style="margin-right: 0.5rem">
              <span class="icon">
                <lord-icon animation="hover" target="button" params="200" palette="#121331;#03a9f4"
                  src="/lordicon/19-magnifier-zoom-search-outline.json"></lord-icon>
              </span>
              <span>Szczegóły {{ broker.name }}</span>
            </button>
          </router-link>
          <a :href="broker.create_account_link + '/review' + addRef()" rel="noopener noreferrer" target="_blank">
            <button class="button is-large is-info">
              <span>Załóż konto w {{ broker.name }}</span>
              <span class="icon">
                <lord-icon animation="hover" target="button" params="200" palette="#ffffff;#000000"
                  src="/lordicon/20-love-heart-outline.json"></lord-icon>
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="column is-3"></div>
  </div>
</template>

<script>
import articles from "@/articles.json";
import RankingWidget from "./RankingWidget";
import ArticleWidget from "./ArticleWidget";
import BrokerListElement from "./BrokerListElement";
import AuthorBox from "./AuthorBox";
import SideToSideCompareColumns from "./SideToSideCompareColumns";

export default {
  data() {
    return {
      article: null,
      broker: null,
      ranking: null,
      relatedArticle: null,
      fields: {
        description: false,
        rate: true,
        minInstallment: false,
        currencies: false,
        countryOfOrigin: false,
        investmentProtection: false,
        platforms: false,
        countryOfRegulation: false,
        instruments: true,
        spread: false,
        commission: false,
        fundingMethods: true,
        leverage: true,
        ecn: false,
      },
    };
  },
  methods: {
    setState() {
      this.article = articles.find(
        (elem) => elem.slug == this.$route.params.slug
      );
      this.$store.commit("setCurrentPageTitle", this.article.title);
      if (this.article.broker !== null) {
        this.broker = this.$store.state.brokers.find(
          (elem) => elem.slug == this.article.broker
        );
      }
      if (this.article.ranking !== null) {
        this.ranking = this.$store.state.rankings.find(
          (elem) => elem.slug == this.article.ranking
        );
      }
      if (this.article.relatedArticle !== null) {
        this.relatedArticle = articles.find(
          (elem) => elem.slug == this.article.relatedArticle
        );
      }
    },
  },
  created() {
    this.setState();
  },
  computed: {
    articleLoader() {
      // it's here just to invoke reactivity
      this.$route.params.slug;
      return () => import(`@/articles/${this.$route.params.slug}.md`);
    },
    metaTitle() {
      return this.article ? this.article.title : "JakiBroker.pl";
    },
    metaDescription() {
      return this.article
        ? this.article.description
        : "Pomożemy ci wybrać brokera";
    },
    metaImageSrc() {
      return this.article ? this.article.coverImageSrcJpg : "";
    },
    metaUrlContent() {
      return this.article
        ? `https://jakibroker.pl/bazawiedzy/${this.article.slug}/`
        : "https://jakibroker.pl/bazawiedzy/";
    },
  },
  metaInfo() {
    return {
      meta: [
        // general
        { name: "description", content: this.metaDescription },

        // Schema.org for Google
        { itemprop: "name", content: this.metaTitle },
        { itemprop: "description", content: this.metaDescription },
        { itemprop: "image", content: this.metaImageSrc },

        // twitter
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: this.metaTitle },
        { name: "twitter:description", content: this.metaDescription },
        { name: "twitter:image:src", content: this.metaImageSrc },

        // Open Graph general (Facebook, Pinterest & Google+)
        { property: "og:title", content: this.metaTitle },
        { property: "og:description", content: this.metaDescription },
        { property: "og:image", content: this.metaImageSrc },
        { property: "og:image:url", content: this.metaImageSrc },
        { property: "og:image:width", content: 1200 },
        { property: "og:image:height", content: 628 },

        { property: "og:image:alt", content: this.metaTitle },
        { property: "og:url", content: this.metaUrlContent },
        { property: "og:locale", content: "pl_PL" },
        { property: "og:type", content: "link" },
      ],
      title: this.metaTitle,
      description: this.metaDescription,
      titleTemplate: "JakiBroker.pl - %s",
    };
  },
  watch: {
    $route() {
      this.setState();
    },
  },
  components: {
    RankingWidget,
    ArticleWidget,
    BrokerListElement,
    AuthorBox,
    SideToSideCompareColumns,
  },
};
</script>
